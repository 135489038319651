.order-teaser {
  @apply
    p-4
    mb-4
    basic-transition;

  h4 {
    @apply
      text-2xl
      font-bold
      text-primary-blue;

    time {
      @apply
        italic
        float-right
        text-xs
        mt-2
        opacity-75;
    }
  }

  h5 {
    @apply
      text-base
      text-primary-lblue;
  }

  hr {
    @apply
      mt-2
      mb-4
      border-dashed
      border-primary-black
      opacity-25;
  }

  ul {
    @apply
      bg-white
      bg-opacity-60
      rounded-sm
      p-2;

    li {
      ul {
        @apply
          mb-2;

        li {
          @apply
            border-b
            border-primary-gray
            mb-1
            justify-between
            relative;

          @screen sm {
            @apply
              flex;
          }

          label {
            @apply
              text-sm;

            @screen sm {
              @apply
                text-base;
            }
          }

          .send-again {
            @apply
              ml-2
              inline-block
              align-text-bottom
              h-5
              w-5
              rounded-full
              bg-primary-lblue
              leading-5
              text-xs
              text-center
              basic-transition;

              &:hover {
                @apply
                  bg-primary-orange;
              }
          }

          .mail-overlay {
            @apply
              absolute
              left-0
              top-full
              absolute
              rounded-sm
              bg-primary-blue
              bg-opacity-95
              text-sm
              text-white
              p-4
              shadow-md
              z-10;

            a {
              @apply
                rounded-full
                px-2
                py-1
                text-sm
                mt-2
                mr-2
                basic-transition
                inline-block
                transform;

              &.ok {
                @apply
                  bg-primary-orange;
              }

              &.not-ok {
                @apply
                  bg-primary-lblue;
              }

              &:hover {
                @apply
                  scale-105
                  shadow-md;
              }
            }
            
            @screen sm {
              @apply
                left-full
                ml-1
                mt-1.5
                top-0;
            }
          }
        }
      }
    }
  }
}