.page-view.campaign {
  @apply
    relative
    pt-16;

  .back-to {
    @apply
      absolute
      top-3
      left-2
      text-xs
      uppercase
      basic-transition
      text-primary-lblue;

    > svg {
      @apply
        transform
        scale-75
        inline-block
        align-bottom;
    }

    &:hover {
      @apply
        text-primary-blue;
    }
  }

  .page-title {
    @apply
      mb-2;

    @screen xs {
      @apply
        pr-4;
    }

    @screen xl {
      @apply
        pl-12;
    }
  }

  .subtitle {
    @apply
      text-base;

    @screen xl {
      @apply
        pl-12;
    }
  }

  .item {
    @screen lg {
      @apply
        w-1/2;

      &.large {
        @apply
          w-full;
      }
    }
  }
  
  .item.large {
    @apply
      pb-4
      px-4;

    @screen xs {
      @apply
        pt-6;
    }

    @screen xl {
      @apply
        px-12;
    }

    .end {
      @apply
        text-2xl
        text-white
        bg-red-500
        rounded-lg
        inline-block
        px-4
        py-3
        -mb-2
        leading-none
        w-full;

      @screen sm {
        @apply
          w-auto;
      }

      small {
        @apply
          text-sm;
          
        > * {
          @apply
            text-sm;
        }
      }
    }

    .new-order {
      header {
        @apply
          w-full
          pt-4
          pb-4;

        @screen lg {
          @apply
            flex
            justify-between;
        }

        h4 {
          @apply
            text-2xl
            text-primary-blue
            font-bold;

          small {
            @apply
              text-sm
              text-primary-lblue
              block
              mb-5;

            @screen xs {
              @apply
                inline-block
                mb-0;
            }
          }
        }

        .button-group {
          @apply
            flex
            justify-between;
        }

        .ahead,
        .behind {
          @apply
            uppercase
            tracking-widest
            basic-transition
            text-sm;

          > svg {
            @apply
              inline-block
              align-sub;
          }
        }

        .ahead {
          @apply
            ml-2
            text-primary-orange;

          &:hover {
            @apply
              text-primary-dorange;
          }

          @screen xs {
            @apply
              ml-4;
          }
        }

        .behind {
          @apply
            mr-2
            text-primary-lblue;

          &:hover {
            @apply
              text-primary-blue;
          }

          @screen xs {
            @apply
              mr-4;
          }
        }
      }

      .disclaimer {
        @apply
          italic
          text-xxs
          tracking-wider
          opacity-50
          inline-block
          mt-6;
      }

      .table-wrapper {
        @apply
          rounded-lg
          bg-white
          shadow-md
          mt-4
          pl-4
          pr-6
          py-4
          text-left
          overflow-auto;

        @screen lg {
          @apply
            pl-12
            pr-16;
        }

        &.overflowed {
          @screen xs {
            @apply
              overflow-visible;
          }
        }

        table {
          @apply
            table-fixed
            w-full;

          thead {
            @apply
              w-full;

            th {
              @apply
              border-b
              border-white
              pb-2
              w-40;

              &.minus,
              &.plus {
                @apply
                  w-auto;
              }

              // &.article {
              //   @screen xs {
              //     width: 25%;
              //   }
              // }
              
              // &.desc {
              //   @screen xs {
              //     width: 25%;
              //   }
              // }

              // &.qta {
              //   @screen xs {
              //     width: 10%;
              //   }
              // }

              // &.card {
              //   @screen xs {
              //     width: 20%;
              //   }
              // }

              // &.exp {
              //   small {
              //     @apply
              //       block
              //       text-xxs
              //       leading-none;
              //   }

              //   @screen xs {
              //     width: 25%;
              //   }
              // }
            }
          }

          tbody {
            tr {
              @apply
                relative;

              td {
                @apply
                  pt-4
                  px-1;

                vertical-align: top;

                p {
                  @apply
                    px-4
                    text-xs
                    max-h-16
                    overflow-hidden
                    relative;

                  // &::after {
                  //   content: '';

                  //   @apply
                  //     absolute
                  //     bottom-0
                  //     inset-x-0
                  //     h-5
                  //     bg-gradient-to-t
                  //     from-primary-lgray
                  //     to-transparent;
                  // }
                }

                .add,
                .remove {
                  @apply
                    transform
                    absolute
                    top-5
                    scale-150
                    text-primary-blue
                    cursor-pointer
                    basic-transition;

                  &:hover {
                    @apply
                      text-primary-orange;
                  }
                }
                
                .add {
                  @apply
                    -right-8;
                }

                .remove {
                  @apply
                    -left-8
                    text-primary-lblue;
                }

                &.resume {
                  @apply
                    text-sm
                    opacity-75
                    border-b
                    border-primary-gray
                    pb-1;

                  > * {
                    @apply
                      text-sm;
                  }
                }
              }
            }
          }

          tfoot {
            button {
              @apply
                w-full
                bg-primary-orange
                text-white
                px-4
                py-2
                basic-transition
                mt-4
                rounded-md;

              &:hover {
                @apply
                  bg-primary-dorange;
              }
            }
          }
        }

        select,
        input {
          @apply
            w-full
            h-full
            px-3
            pt-2
            pb-1
            rounded-md
            border-primary-gray
            border
            text-primary-lblue;

          // min-width: 150px;

          &[type=checkbox] {
            @apply
              w-auto;

            min-width: auto;

            & + label {
              @apply
                text-xs
                inline-block
                ml-1
                align-middle;
            }
          }
        }

        &.order-table {
          @apply
            p-0;

          table {
            @apply
              block
              table-auto;

            tbody {
              @apply
                block;
                
              .row {
                @apply
                  bg-primary-lblue
                  border-dashed
                  bg-opacity-10
                  border-t
                  py-2
                  px-4
                  relative
                  border-primary-lblue;

                .column {
                  @apply
                    mb-2;

                  input {
                    @apply
                      w-full;
                  }
                }

                &:nth-of-type(even) {
                  @apply
                    bg-transparent;
                }
              }

              @screen lg {
                @apply
                  flex
                  flex-wrap;

                .row {
                  @apply
                    w-1/2;

                  &:nth-of-type(even) {
                    @apply
                      border-l;
                  }
                }
              }
            }

             .add,
            .remove {
              @apply
                absolute
                right-4
                top-2
                transform
                scale-150
                text-primary-blue
                cursor-pointer
                basic-transition;

              &.remove {
                @apply
                  right-12;
              }

              &:hover {
                @apply
                  text-primary-orange;
              }
            }
            // tbody {
            //   tr {
            //     td {
            //       @apply
            //         w-full;
            //     }
            //   }
            // }
            
          }
        }
      }
    }
  }

  .divider {
    @apply
      mt-10;
  }

  .past-filters {
    @apply
      bg-gray-100
      py-3;

    h6 {
      @apply
        font-bold
        uppercase
        text-primary-blue
        tracking-wide;
    }

    ul {
      @apply
        pt-2;

      @screen sm {
        @apply
          flex;
      }

      li {
        @apply
          mr-4
          w-full
          mb-2;

        @screen sm {
          @apply
            w-auto
            mb-0;

          &.field {
            // min-width: 15rem;
          }
        }

        input {
          @apply
            w-full
            border
            border-primary-gray
            rounded-md
            px-2
            pt-1;
        }

        .btn {
          @apply
            pb-1
            pt-2
            leading-3
          text-white uppercase;

          &.reset {
            @apply
              bg-primary-lblue;
          }

          &.go {
            @apply
              bg-primary-orange;
          }
        }
      }
    }
  }

  .past-orders {
    @apply
      mt-10
      pt-10
      pb-20;

    .error {
      @apply
        text-primary-black
        text-center
        opacity-30;
    }

    ul {
      @apply
        flex
        flex-wrap;

      li {
        @apply
          w-full;

        @screen sm {
          @apply
            w-1/2
            px-2;
        }
        
        @screen lg {
          @apply
            w-1/3;
        }
      }
    }

    article {
      @apply
        bg-primary-lgray
        shadow-md;

      &:hover {
        @apply
          shadow-lg;
      }
    }
  }

  .export-orders {
    @apply
      mt-10;

    hr {
      @apply
        -mx-5;

      @screen sm {
        @apply
          mx-0;
      }
    }

    h6 {
      @apply
        block
        pt-5
       text-primary-blue
        text-xs;
    }

    .btn {
      @apply
        text-white
        pt-2
        mt-2.5;
    }
  }
}
