.advanced-search {
  @apply
    w-full
    relative;

  > input {
    @apply
      w-full
      border
      border-primary-gray
      rounded-md
      px-2
      pt-1;
  }

  .search-list {
    @apply
      absolute
      left-0
      max-w-xl
      bg-white
      max-h-40
      z-50
      overflow-auto;

    top: 100%;

    span {
      @apply
        block
        px-2
        py-1
        cursor-pointer
        relative
        text-sm;

      &:hover {
        @apply
          bg-gray-100;
      }

      &::before {
        content: '';

        @apply
          absolute
          top-3
          left-0.5
          h-1
          w-1
          inline-block
          bg-gray-200
          rounded-full;
      }
    }

    @screen sm {
      @apply
        inset-x-0;

      span {
        @apply
          px-4
          text-base;

        &::before {
          @apply
            left-1.5;
        }
      }
    }
  }
}